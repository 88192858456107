html {
  overflow-x: visible;
  overflow-y: unset;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
  padding: 0px;
}

.container {
  padding: 0;
}

.v-application--wrap {
  min-height: auto !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgb(255, 243, 236) !important;
  padding: 0 5px !important;
  min-width: 30px !important;

  &--active {
    border-color: rgb(255, 129, 65) !important;
  }

  &:not(:first-child) {
    border-left-width: thin !important;
  }
}

.event_date_time_form {
  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }

  .v-text-field__details {
    display: none !important;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-size--default {
    height: 30px !important;
  }

  .v-btn {
    border-color: rgb(255, 243, 236) !important;
    background-color: #FFFFFF !important;

    &::before {
      background-color: inherit;
    }

    &__content {
      color: rgb(255, 243, 236) !important;
      font-weight: 400 !important;
    }
  }

  .v-btn--active {
    border-color: rgb(255, 129, 65) !important;
    background-color: #FFFFFF !important;

    .v-btn {
      &__content {
        color: rgb(255, 129, 65) !important;
      }
    }
  }
}

.container {
  padding: initial;
}

.v-menu__content {
  min-width: 290px !important;
}

.v-picker__title__btn {
  color: #454F69;
}

.v-picker__title__btn--active {
  color: #ff8141;
}

@media screen and (max-width: 640px){
  .v-menu__content {
    min-width: 200px !important;
  }

  .v-picker__body {
    height: 200px;
    width: 200px;
  }

  .v-time-picker-clock__item:after,
  .v-time-picker-clock__item:before,
  .v-time-picker-clock__item {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 768px){
  .v-time-picker-clock__container {
    height: 100%;
  }
}
