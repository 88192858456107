





















































































































































.forms__item-wp {
  position: relative;

  .password-watch-icon {
    top: 10px;
  }
}
